.top-bar {
    width: 100%;
    height: 120px;
    background-color: white;
    transition: 0.6s ease;
    display: flex;
    box-shadow: 0 1px 0 rgb(12 13 14 / 15%);
    position: relative
}
.side-bar-icon{
    fill:lightgreen;
    margin-top:12px;
    margin-left:12px;
    width:25px;
    height:25px;
}
.top-logo{
width:100%;
max-width:150px;
height:80px;
margin-top:10px;
margin-bottom:auto;
    margin-left:100px;

}
.scroll-down .top-bar {
    position: fixed;
    top: 0;
    z-index: 1;
    transform: translate3d(0, -100%, 0);
}
.loader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid blue;
    width: 15px !important;
    min-width: 15px;
    padding: 0 !important;
    height: 15px !important;
    -webkit-animation: spin .5s linear infinite; /* Safari */
    animation: spin .5s linear infinite;
    margin-left: 10px;
    margin-bottom: 0 !important;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.scroll-up .top-bar {
    position: fixed;
    top: 0;
    z-index: 1;
}

.links{
    margin:auto;
    display:flex;
}
    .links a {

        margin:auto;
        margin-left: 50px;
        text-decoration: none;
        color: black;
        text-transform: uppercase;
        word-break:keep-all;
    }
        .links a:first-child {
            margin-left: 0;
        }
        .links a:hover {
            color: #4172ae;
        }
.ll {
    width: 85%;
    max-width: 150px;
}
.links button {
    margin: auto;
    margin-left: 50px;
    text-decoration: none;
    background-color: #4172ae;
    border: none;
    width: 120px;
    height: 50px;
    border-radius: 50px;
    text-transform: uppercase;
    color: white;
    cursor: pointer;
}
a{
    cursor:pointer;
}
.links button:hover {
    background-color: #2196f3;
    color: white;
}
.don {
    margin: auto;
    text-decoration: none;
    background-color: #4172ae;
    border: none;
    width: 120px;
    height: 50px;
    border-radius: 50px;
    text-transform: uppercase;
    color: white;
    cursor: pointer;
}
    .don:hover {
        background-color: #2196f3;
        color: white;
    }
.re-b:hover {
    background-color: #2196f3;
    color: white;
}
.re-bb:hover {
            background-color: #2196f3;
            color:white;
        }
.banner {

    text-align:right;
    width: 100%;
    height: 680px;
    background-image: url(./img/banner.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display:flex;
 
}
.Na {
    color: white;
    font-size: 50px;
    font-weight: bolder;
    margin-top: 120px;
    z-index: 10;
    text-decoration: none;
    outline: none;
    -webkit-user-select: none;
    text-align: center;
}
.Nan {
    font-size: 50px;
    font-weight: bolder;
    color: #4172ae;
        margin: auto;
        z-index: 10;
    text-decoration: none;
    outline: none;
    text-align: center;
}
.s-t {
    width: 100%;
    max-width: 150px;
    margin-left: 100px;
    color: blue;
    font-weight: bolder;
}
.wel {
    font-family: Tangerine, Arial, Helvetica, sans-serif;
    font-size: 70px;
    text-align: center;
    color: #4172ae;
    margin: 50px;
}
.re-b {
    margin: 50px;
    text-decoration: none;
    background-color: #4172ae;
    border: none;
    width: 120px;
    height: 40px;
    border-radius: 50px;
    text-transform: uppercase;
    color: white;
    cursor: pointer;
}
button{
    outline:none;
    cursor:pointer;
}
.re-bb {
    margin: auto;
    text-decoration: none;
    background-color: white;
    border: none;
    width: 120px;
    height: 40px;
    border-radius: 50px;
    text-transform: uppercase;
    color: #4172ae;
}
.minis {
    width: 400px;
    height: 250px;
    background-color: #4172ae;
    margin: 10px;
    font-size: 30px;
    color: white;
    display: flex;
    font-weight: bolder;
    flex-direction: column;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius:10px;
}
a, button {
    transition: 0.6s linear;
}
footer {
    background-color: black;
    padding: 20px;
    color: white;
    display: flex;
    flex-wrap: wrap;
}
    footer a {
        color: rgba(255,255,255,.6);
        text-decoration: none;
    }
    footer a:hover {
        color: white;
        text-decoration: underline;
    }
.f-co {
    font-weight: bolder;
    letter-spacing: 1.5px;
    font-size: 16px;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 4px;
}
    .f-co:before {
        content: "";
        position: absolute;
        width: 30px;
        height: 2px;
        background-color: rgba(255,255,255,.8);
      bottom:0;
    }
.social {
    display: flex;
    flex-wrap: wrap;
}
.sl {
    width:800px;
    margin:auto;
}
.me-i{
    margin-left:auto;
}
.t-n {
    width: 30px;
    height: 30px;
    margin: auto;
    margin-left: 10%;
    fill: #4172ae;
    cursor: pointer;
    display: none;
}
@media (min-width: 992px) {
    footer {
        padding: 80px;
    }
}
@media screen and (max-width:1165px){
    .sl {
        text-align:center;
        width: 500px;
        margin: auto;
    }
    .animate span{
        font-size:40px;
    }
    .animate {
        font-size: 40px;
    }
    }
@media screen and (max-width:865px) {
    .sl {
        text-align: center;
        width: 400px;
        margin: auto;
    }

}
@media screen and (max-width:765px) {
  .banner{
      height:550px;
  }
   
}
@media screen and (max-width:693px) {
    .banner {
        height: 500px;
    }
    .sl {
        text-align: center;
        width: 300px;
        margin: auto;
    }
   
}

@media screen and (max-width:699px) {
    .banner {
        height: 500px;
    }

    .sl {
        text-align: center;
        width: 300px;
        margin: auto;
    }

    .animate span {
        font-size: 20px;
    }

    .animate {
        font-size: 20px;
    }

    .Na {
        font-size: 30px;
    }
}
@media screen and (max-width:575px) {
    .banner {
        display: block;
        background-image: url(./img/banners.png);
        padding-top: 50px;
        height: 100%;
        text-align: center;
    }
    .me-i{
       text-align:center;
       height:500px
    }
    .Na{
        margin-top:0;
    }
}
@media screen and (max-width:320px) {
    .me-i {
        text-align: center;
        height: 300px
    }
    .sl {
        text-align: center;
        width: 100px;
        margin: auto;
    }
    .wel{
        margin:0;
        word-break:break-word;
    }
    .animate span {
        color:blue;
    }
    .re-b {
       margin: 0;
    }
  .minis{
      font-size:20px;
  }
    .Nan {
        font-size: 30px;
    }
    }
@media screen and (max-width:1200px){
   .top-logo{
       margin-left:15%;
   }
    .s-t {
        margin-left: 15%;
    }
}
@media screen and (max-width:1000px) {
    .top-logo {
        margin-left: 10px;
    }

    .s-t {
        margin-left: 10px;
    }
}
@media screen and (max-width:995px) {
    .links{
        margin-right: 5px;
    }
   
}
@media screen and (max-width:955px) {
    .links {
        display: none;
    }

    .ll {
        margin-left: auto;
        margin-right: 30px;
    }

    .t-n {
        display: block;
    }
}

@media screen and (max-width:300px) {
    .t-n {
        margin-left: 10px;
    }
}
.side-nav {
    width: 0;
    overflow-y: scroll;
    height: 100%;
    position: fixed;
    z-index: 5050;
    background-color: #0C0C0C;
    transition: 0.6s ease;
}
.side-nav-t {
    width: 0;
    height: 0;
    position: fixed;
    z-index: 50;
    background-color: #00000080;
   
}
.s-l {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 15px;
    padding-left: 15px;
    border-bottom: 1px solid rgba(92,92,92,.23);
}
    .side-nav a {
        margin: auto;
        text-decoration: none;
        color: white;
        text-transform: uppercase;
        word-break: keep-all;
    }

        .side-nav a:hover {
            color: #4172ae;
        }
@media screen and (min-width:955px) {
    .side-nav {
        display: none;
    }
    .side-nav-t {
        display: none;
    }
}
.s-w{
    width:270px;
}
.s-c {
    width: 100%;
    height: 100%;
}
@media screen and (max-width:450px) {
    .s-w {
        width: 170px;
    }
}
@media screen and (max-width:270px) {
    .s-w {
        width: 100px;
    }
}

.m-i{
    margin:auto;
    height:500px;
}
.m-b-t {
    font-size: 40px;
    font-weight: bolder;
    color: #4172ae;
    margin-bottom: 30px;
    word-break: keep-all;
}
.b-i{
    max-width:660px;
    margin:auto;
    word-break:keep-all;
    text-align:center;
}
.b-box {
    margin:auto;
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    padding: 30px;
    margin-top: 30px;
    width:80%;
    border-width: 1px;
    border-style: solid;
    margin-bottom: 30px;
    border-radius: 10px;
}
@media screen and (max-width:370px) {
    .b-box {
        width: 70%;
    }
}
@media screen and (max-width:250px) {
    .b-box {
        width: 60%;
        padding:20px;
    }
}
@media screen and (max-width:160px) {
    .b-box {
        width: 70%;
        padding: 10px;
    }
}
.bii {
    display:flex;
}
@media screen and (max-width:1000px){
    .bii {
        display: block;
        text-align: center;
    }
}
@media screen and (max-width:500px) {
    .m-i {
        height: 400px;
    }
    .m-b-t {
        font-size: 30px;
    }
}
@media screen and (max-width:300px) {
    .m-i {
        height: 300px;
    }

    .m-b-t {
        font-size: 25px;
    }
}
@media screen and (max-width: 200px) {
    .m-i {
        height: 200px;
        width: 100%;
    }
}
@media screen and (max-width: 200px) {
    .me-i {
        width: 100%;
    }
}
@media screen and (max-width: 856px) {
    .minis {
        width: 300px;
        margin-left: 20px;
        margin-right: 20px;
    }
}
@media screen and (max-width: 696px) {
    .minis {
        width: 100%;
        margin-left: 20px;
        margin-right: 20px;
    }
}
.su{
    font-size:20px;
}
@media screen and (max-width:300px) {
    .su {
        font-size: 15px;
    }
}
.b-boxx {
    border-color: #f0f0f0;
    background-color: white;
    padding: 30px;
    margin: auto;
    margin-bottom:0;
    max-width:500px;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
}
    .b-boxx div {
        font-size: 24px;
    }
@media screen and (max-width:560px) {
    .b-boxx {
        margin: 30px;
        margin-bottom: 0;
    }
}
.part-btn {
    text-align:center;
    margin: auto;
    text-decoration: none;
    background-color: #4172ae;
    border: none;
    width: 120px;
    height: 50px;
    border-radius: 50px;
    text-transform: uppercase;
    color: white;
    cursor: pointer;
}
    .part-btn:hover {
        background-color: #2196f3;
        color: white;
    }
label{
font-size:18px;
}
.minis a {
    margin: auto;
}
.vid{
    margin:auto;
    max-width:800px;
    height:400px;
}
.contact-form{
    display: block;
}
.sv {
    background-color: white;
    width: 50px;
    height:50px;
    margin:auto;
    margin-right:10px;
    border-radius: 5px;
    border: 3px solid blue;
}

.profile-preview {
    background-color: blue;
    width: 110px;
    height: 110px;
    border-radius: 50%;
}

    .contact-form label{
        text-align:left;
        color:blue;
    }
    .contact-form input[type=text] {
        width: 90%;
        min-width: 200px;
        max-width: 30%;
        height: 50px;
        border: 3px solid #4172ae;
        margin: 2%;
        outline: none;
        border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    }
    .contact-form input[type=password] {
        width: 90%;
        min-width: 200px;
        max-width: 30%;
        height: 50px;
        border: 3px solid #4172ae;
        margin: 2%;
        outline: none;
    }
    .contact-form input[type=email] {
        width: 90%;
        max-width: 30%;
        min-width: 200px;
        height: 50px;
        border: 3px solid #4172ae;
        margin: 2%;
        outline: none;
        border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    }
    .contact-form input[type=number] {
        width: 90%;
        max-width: 30%;
        min-width: 200px;
        height: 50px;
        border: 3px solid #4172ae;
        margin: 2%;
        outline: none;
        border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    }
    .contact-form input[type=date] {
        width: 90%;
        max-width: 30%;
        min-width: 200px;
        height: 50px;
        border: 3px solid #4172ae;
        margin: 2%;
        outline: none;
    }
    .contact-form textarea {
        resize: none;
        width: 90%;
        min-width: 200px;
        max-width: 66%;
        height: 200px;
        border: 3px solid #4172ae;
        margin-bottom: 20px;
        margin: 2%;
        outline: none;
        border-radius: 10px;
    padding: 10px;
    }
.contact-form button[type=submit] {
    display: flex;
    width: 100%;
    margin: auto;
    height: 50px;
    border: 3px solid #4172ae;
    margin-bottom: 20px;
    border-radius: 50px;
    max-width: 1000px;
    outline: none;
    align-content: center;
    justify-content: center;
    align-items: center;
}
    .u{

        color:red;
        font-size:20px;
        font-weight:bolder;
    }
.y {

    color: green;
    font-size: 20px;
    font-weight: bolder;
}
.load {
    margin: auto;
    margin-bottom: 50px;
    border: 3px solid blue;
    border-radius: 50%;
    width: 20px;
    border-left-color: transparent;
    height: 20px;
    text-align: center;
    animation: spinner-border .75s linear infinite;
}
@keyframes spinner-border {
    100% {
        transform: rotate(360deg);
    }
}
.box {
    width: 90%;
    border: 1px solid blue;
    max-width: 800px;
    margin: auto;
    padding-bottom: 20px;
    border-radius: 5px;
    color: #074D70;
    margin-top: 10px;
    
}
    .box p {
        text-align: left;
        margin-left: 10px;
    }
.box-h {
    width: 100%;
    height: 80px;
    border-bottom: 1px solid blue;
    display: flex;
    font-size: 20px;
    color: #2da5ff;
    
}
.bo {
    padding: 20px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: left;
}
.bo li{
    margin-left:40px;
}
    .bo img {
        margin-left: 40px;
        margin-top:8px;
    }
@media screen and (max-width:800px) {
    .bo img {
        width: 100%;
        margin-left: 0;
    }

    .bo li {
        margin-left: 0;
    }

    .bo {
        padding: 5px;
    }
}
@media screen and (max-width:320px) {
    .box-h {
        height: fit-content;
        display: block;
    }
}
.bbb {
    margin: auto;
    width: unset;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
    height: 50px;
    border: 3px solid blue;
    margin-bottom: 20px;
    border-radius: 50px;
    max-width: 1000px;
    font-weight: bolder;
    outline: none;
    color: blue;
    display: flex;
    transition: 0.6s ease;
}
    .bbb svg {
        fill: blue;
        transition: 0.6s ease;
    }
    .bbb:hover svg {
        fill: white;
    }
    .bbb:hover {
        border: 3px solid #2196f3;
        background-color: #2196f3;
        color: white;
    }

.App{
    padding:30px;
}
.App input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    font-size: 16px;
    width: 100%;
    background: white;
    box-sizing: border-box;
}
.result-message {
  line-height: 22px;
  font-size: 16px;
}
.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}
.hidden {
  display: none;
}
#card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}
#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}
#payment-request-button {
  margin-bottom: 32px;
}
/* Buttons and links */
.App button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 0 0 4px 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}
/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.b-boe {
    width: 50vw;
    margin: auto;
    padding: 30px;
}

    @media only screen and (max-width: 850px) {
        .b-boe {
            width: 60%;
        }
    }
.com {
    color: black;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.com input[type=number] {
    width: 90%;
    font-size: 60px;
    max-width: 30%;
    min-width: 220px;
    height: 80px;
    border: 3px solid blue;
    margin: 2%;
    outline: none;
    border-radius:10px;
}
.card-error{
    color:red;
}
.result-message{
    color:green;
}
.tbo {
    font-size: 32px;
    color: #111;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    padding-bottom: 15px;
}
.tbo {
    text-align: center;
}
    .tbo:after {
        width: 40px;
        height: 3px;
        background: #00f;
        bottom: 0;
        left: calc(50% - 20px);
    }

    .tbo:after, .tbo:before {
        content: "";
        position: absolute;
        display: block;
    }
    .tbo:before {
        width: 120px;
        height: 1px;
        background: #ddd;
        bottom: 1px;
        left: calc(50% - 60px);
    }
.mobile__pdf__container #viewerContainer .pdfViewer {
    overflow: hidden !important;
    padding-bottom: 200px;
}
.mobile__pdf__container #viewerContainer {
    position: absolute !important;
    overflow: auto !important;
    width: 100% !important;
    top: 0 !important;
    bottom: 1rem !important;
    left: 0 !important;
    height: 100% !important;
    z-index: 1 !important;
    right: 0 !important;
    background: white !important;
}
video{
    width:calc(100% - 40px);
    height:500px;
    background-color:black;
    padding:20px;
}
li::marker{
    color:blue
}

.payment-form{
    width: 50vw;
    padding: 40px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
    margin: auto;
}
@media only screen and (max-width: 800px) {
    .payment-form{
        width: 80%;
        padding: 40px;
    }
}
@media only screen and (max-width: 500px) {
    .payment-form{
        width: auto;
        padding: 0;
    }
}

.videos-container{
    display: flex;
    width: 100%;
}
.videos-container div{
    width: 100%;
    max-width: 500px;
    margin: 10px;
    padding-top:113%
}
.videos-container video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding:0;
}
.videos-container div{
    display: block;
    vertical-align: top;
    box-sizing: border-box;
    color: #fff;
    background-color: #000;
    position: relative;
    font-size: 10px;
    line-height: 1;
    font-family: serif, Times, "Times New Roman";
    -webkit-user-select: none;
    user-select: none;
}
@media only screen and (max-width: 700px) {
.videos-container{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    flex-direction: column;
    width: 100%;
}
.videos-container div{
    width: 100% !important;
    height: 90vh;
    max-width: 500px;
    padding-top: 0;
}
.videos-container video{
    width:100% !important;
    position:relative;
    max-height: 90vh;
    max-width: 500px;
    padding:0
}
}
.bg-blue{
    background-color: #4172ae;
    color: white;
}
.book-bg{
    display: flex;
    gap: 50px;
    padding: 70px 10px 70px 10px;
    margin: 0 auto;
max-width: 1200px;
}
.book-text-container{
    width: 55%;
    padding-left: 20px;
gap: 10px;
display: flex;
flex-direction: column;
}
.book-img-container{
    width: 45%;
padding-right: 20px;
display: flex;
align-items: flex-start;
}
.book-img{
    width: 100%;
    border-radius: 5px;
max-width: 535px;
margin-left:auto;
}
.book-release{
    color: #055C9D;
    font-family: Sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px;
}
.book-title{
    font-size: 57px;
    line-height: 60px;
    color: #055C9D;
    font-family: Sans-serif;
margin: 10px 0;
}
.book-description{
    color: #30333A;
    font-family: Sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
}
.book-author{
    color: #055C9D;
    font-family: Sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px;
}
.book-price{
    color: #055C9D;
    font-family: Sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px;
}
@media screen and (max-width: 767px){
.book-bg{
flex-direction: column;
}
.book-text-container{
width: calc(100% - 40px);
padding-right: 20px;
}
.book-img-container{
width: calc(100% - 50px);
margin: 0 25px;
}
}
.images-list{
    column-count: 3;
    column-gap: 8px;
    display: block;
    overflow-y: auto;
    list-style: none;
    padding: 0 20px;
    -webkit-overflow-scrolling: touch;
    max-width: 1200px;
}
.images-list-item{
    height: auto;
    margin-bottom: 8px;
    break-inside: avoid;
    display: block;
    position: relative;
}
.images-list-item-image{
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
}
@media screen and (max-width: 767px){
    .images-list{
        column-count: 2;
    }
}
@media screen and (max-width: 500px){
    .images-list{
        column-count: 1;
    }
}