body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break:break-word;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*.take-input {
  margin: 50px 0 0;
}

.take-input input {
  width: 400px;
  height: 35px;
  padding: 0 10px;
  border-radius: 5px;
  border: 1px solid #ececec;
  margin: 0 15px 0 0;
  font-size: 15px;
}*/


.animate {
    font-size: 50px;
    z-index:10;
}

    .animate span {
        display: inline-block;
        z-index: 10;
        color: #4172ae;
        font-size: 50px;
        font-weight: bolder;
        text-decoration: none;
        outline: none;
        -webkit-user-select: none;
        transition: 0.6s ease;
    }

.animate span:nth-of-type(2) {
    animation-delay: .05s;
}

.animate span:nth-of-type(3) {
    animation-delay: .1s;
}

.animate span:nth-of-type(4) {
    animation-delay: .15s;
}

.animate span:nth-of-type(5) {
    animation-delay: .2s;
}

.animate span:nth-of-type(6) {
    animation-delay: .25s;
}

.animate span:nth-of-type(7) {
    animation-delay: .3s;
}

.animate span:nth-of-type(8) {
    animation-delay: .35s;
}

.animate span:nth-of-type(9) {
    animation-delay: .4s;
}

.animate span:nth-of-type(10) {
    animation-delay: .45s;
}

.animate span:nth-of-type(11) {
    animation-delay: .5s;
}

.animate span:nth-of-type(12) {
    animation-delay: .55s;
}

.animate span:nth-of-type(13) {
    animation-delay: .6s;
}

.animate span:nth-of-type(14) {
    animation-delay: .65s;
}

.animate span:nth-of-type(15) {
    animation-delay: .7s;
}

.animate span:nth-of-type(16) {
    animation-delay: .75s;
}

.animate span:nth-of-type(17) {
    animation-delay: .8s;
}

.animate span:nth-of-type(18) {
    animation-delay: .85s;
}

.animate span:nth-of-type(19) {
    animation-delay: .9s;
}

.animate span:nth-of-type(20) {
    animation-delay: .95s;
}
        .animate span:nth-of-type(21) {
            animation-delay: 1s;
        }

        .animate span:nth-of-type(22) {
            animation-delay: 1.1s;
        }
        .animate span:nth-of-type(23) {
            animation-delay: 1.2s;
        }
        .animate span:nth-of-type(24) {
            animation-delay: 1.3s;
        }

        .animate span:nth-of-type(25) {
            animation-delay: 1.4s;
        }

        .animate span:nth-of-type(26) {
            animation-delay: 1.5s;
        }
        .animate span:nth-of-type(27) {
            animation-delay: 1.6s;
        }

        .animate span:nth-of-type(28) {
            animation-delay: 1.7s;
        }

        .animate span:nth-of-type(29) {
            animation-delay: 1.8s;
        }

        .animate span:nth-of-type(30) {
            animation-delay: 1.9s;
        }

        .animate span:nth-of-type(31) {
            animation-delay: 2s;
        }

        .animate span:nth-of-type(32) {
            animation-delay: 2.1s;
        }
        .animate span:nth-of-type(33) {
            animation-delay: 2.2s;
        }

        .animate span:nth-of-type(34) {
            animation-delay: 2.3s;
        }

        .animate span:nth-of-type(35) {
            animation-delay: 2.4s;
        }

        .animate span:nth-of-type(36) {
            animation-delay: 2.5s;
        }

        .animate span:nth-of-type(37) {
            animation-delay: 2.6s;
        }

        .animate span:nth-of-type(38) {
            animation-delay: 2.7s;
        }
        .animate span:nth-of-type(39) {
            animation-delay: 2.8s;
        }

        .animate span:nth-of-type(40) {
            animation-delay: 2.9s;
        }

        .animate span:nth-of-type(41) {
            animation-delay: 3s;
        }

        .animate span:nth-of-type(42) {
            animation-delay: 3.1s;
        }

        .animate span:nth-of-type(43) {
            animation-delay: 3.2s;
        }

        .animate span:nth-of-type(44) {
            animation-delay: 3.3s;
        }

        .animate span:nth-of-type(45) {
            animation-delay: 3.4s;
        }

        .animate span:nth-of-type(46) {
            animation-delay: 3.5s;
        }

        .animate span:nth-of-type(47) {
            animation-delay: 3.6s;
        }

        .animate span:nth-of-type(48) {
            animation-delay: 3.7s;
        }

        .animate span:nth-of-type(49) {
            animation-delay: 3.8s;
        }

        .animate span:nth-of-type(50) {
            animation-delay: 3.9s;
        }

        .animate span:nth-of-type(51) {
            animation-delay: 4s;
        }

        .animate span:nth-of-type(52) {
            animation-delay: 4.1s;
        }

        .animate span:nth-of-type(53) {
            animation-delay: 4.2s;
        }

        .animate span:nth-of-type(54) {
            animation-delay: 4.3s;
        }

        .animate span:nth-of-type(55) {
            animation-delay: 4.4s;
        }

        .animate span:nth-of-type(56) {
            animation-delay: 4.5s;
        }
        .animate span:nth-of-type(57) {
            animation-delay: 4.6s;
        }

        .animate span:nth-of-type(58) {
            animation-delay: 4.7s;
        }

        .animate span:nth-of-type(59) {
            animation-delay: 4.8s;
        }

        .animate span:nth-of-type(60) {
            animation-delay: 4.9s;
        }

        .animate span:nth-of-type(61) {
            animation-delay: 5s;
        }

        .animate span:nth-of-type(62) {
            animation-delay: 5.1s;
        }

        .animate span:nth-of-type(63) {
            animation-delay: 5.2s;
        }

        .animate span:nth-of-type(64) {
            animation-delay: 5.3s;
        }

        .animate span:nth-of-type(65) {
            animation-delay: 5.4s;
        }

        .animate span:nth-of-type(66) {
            animation-delay: 5.5s;
        }

        .animate span:nth-of-type(67) {
            animation-delay: 5.6s;
        }

        .animate span:nth-of-type(68) {
            animation-delay: 5.7s;
        }

        .animate span:nth-of-type(69) {
            animation-delay: 5.8s;
        }
        .animate span:nth-of-type(70) {
            animation-delay: 5.9s;
        }

        .animate span:nth-of-type(71) {
            animation-delay: 6s;
        }

        .animate span:nth-of-type(72) {
            animation-delay: 6.1s;
        }

        .animate span:nth-of-type(73) {
            animation-delay: 6.2s;
        }

        .animate span:nth-of-type(74) {
            animation-delay: 6.3s;
        }
/* Animation Four */


.four span {
    
    opacity: 0;
    transform: translate(0, -100px) rotate(360deg) scale(0);
    animation: revolveDrop .3s forwards;
}


@keyframes revolveDrop {
    30% {
        transform: translate(0, -50px) rotate(180deg) scale(1);
    }

    60% {
        transform: translate(0, 20px) scale(.8) rotate(0deg);
    }

    100% {
        transform: translate(0) scale(1) rotate(0deg);
        opacity: 1;
    }
}
/**/
